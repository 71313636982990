/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
    font-family: "Lato-Black";
    src: url(./assets/fonts/Lato-Black.ttf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Lato-Bold";
    src: url(./assets/fonts/Lato-Bold.ttf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Lato-Italic";
    src: url(./assets/fonts/Lato-BoldItalic.ttf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Lato-Regular";
    src: url(./assets/fonts/Lato-Regular.ttf);
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Arista-Pro-Icons-SemiBold-trial";
    src: url(./assets/fonts/Arista-Pro-Icons-SemiBold-trial.ttf);
    font-style: normal;
    font-weight: 600;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

textarea:focus,
input:focus {
    outline: none;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: Lato-Bold;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d4d4d4;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
}